document.addEventListener('DOMContentLoaded', function () {

    if (document.querySelector('#gifts-slider')) {
        new Glide('#gifts-slider', {
            type: 'carousel', // or 'slider'
            perView: 3,
            gap: 20,
            breakpoints: {
                1024: {
                    perView: 2
                },
                768: {
                    perView: 1
                }
            }
        }).mount();
    }

    // // Get all table cells that represent days in the calendar
    // var tableCells = document.querySelectorAll('.calendar td');

    // // Get the div that will display the highlighted day details
    // var highlightDiv = document.querySelector('[data-day-highlight]');

    // // Get the current month from form
    // let currentMonthIndex = null;
    // const calendarTable = document.querySelector('table[month]');

    // // Check if the table with the 'month' attribute exists
    // if (calendarTable) {
    //     // Get the value of the 'month' attribute
    //     currentMonthIndex = calendarTable.getAttribute('month') - 1;
    // } else {
    //     currentMonthIndex = new Date().getMonth(); // 0 = January, 11 = December
    // }

    // // Get the current month (0-11) and map it to the Latvian month names
    // const currentMonthName = latvianMonths[currentMonthIndex];

    const nameSearchInput = document.getElementById('name-search');
    const availableNamesDiv = document.getElementById('available-names');
    const selectedNamesDiv = document.getElementById('selected-names');
    const selectedNameIdsInput = document.getElementById('selected_name_ids');

    if (nameSearchInput && availableNamesDiv && selectedNamesDiv && selectedNameIdsInput) {

        const noResultsMessage = document.createElement('div');
        noResultsMessage.classList.add('no-results-message');
        noResultsMessage.textContent = 'Atbilstoši vārdi nav atrasti.';
        noResultsMessage.style.display = 'none'; // Hide it initially
        availableNamesDiv.appendChild(noResultsMessage); // Add the message to the DOM

        // Filtering and name selection logic (with diacritic-insensitive search)
        nameSearchInput.addEventListener('input', function () {
            const filter = this.value.toUpperCase();

            // Normalize filter to remove diacritics
            const normalizedFilter = filter.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

            const nameItems = availableNamesDiv.getElementsByClassName('name-item');
            let matchesFound = false; // Track if any names match the search

            for (let i = 0; i < nameItems.length; i++) {
                const nameText = nameItems[i].textContent || nameItems[i].innerText;

                // Normalize name text to remove diacritics
                const normalizedNameText = nameText.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

                if (normalizedNameText.toUpperCase().indexOf(normalizedFilter) > -1) {
                    nameItems[i].style.display = ""; // Show item
                    matchesFound = true; // A match is found
                } else {
                    nameItems[i].style.display = "none"; // Hide item
                }
            }

            // Show or hide the "No matching names found" message
            if (!matchesFound) {
                noResultsMessage.style.display = 'block';
            } else {
                noResultsMessage.style.display = 'none';
            }
        });

        // Add name to selected list logic
        availableNamesDiv.addEventListener('click', function (event) {
            if (event.target && event.target.classList.contains('name-item')) {
                const nameItem = event.target;
                const nameId = nameItem.getAttribute('data-name-id');
                const nameText = nameItem.textContent;

                // Add to selected names and gray out in main list
                nameItem.style.color = 'gray';
                nameItem.style.pointerEvents = 'none';

                const selectedDiv = document.createElement('div');
                selectedDiv.className = 'selected-item';
                selectedDiv.setAttribute('data-name-id', nameId);
                selectedDiv.innerHTML = `${nameText} <button type="button" class="remove-name" data-name-id="${nameId}">✕</button>`;

                // Remove any span elements inside selectedNamesDiv
                const spans = selectedNamesDiv.querySelectorAll('span');
                spans.forEach(function (span) {
                    span.remove();
                });

                selectedNamesDiv.appendChild(selectedDiv);

                // Update hidden input with selected names (ensure it gets populated)
                selectedNameIdsInput.value = [...selectedNamesDiv.querySelectorAll('.selected-item')].map(item => item.getAttribute('data-name-id')).join(',');

                console.log("Selected Name IDs:", selectedNameIdsInput.value);  // Debugging to confirm it's populated
            }
        });

        // Remove name from selected list logic
        selectedNamesDiv.addEventListener('click', function (event) {
            if (event.target && event.target.classList.contains('remove-name')) {
                const nameId = event.target.getAttribute('data-name-id');
                const selectedItem = document.querySelector(`.selected-item[data-name-id="${nameId}"]`);

                // Remove selected name from selected list
                selectedItem.remove();

                // Look inside #available-names for the corresponding name-item
                const nameItem = document.querySelector(`#available-names .name-item[data-name-id="${nameId}"]`);

                if (nameItem) {
                    // Reset color and interactivity of the available name
                    nameItem.style.color = ''; // Reset color
                    nameItem.style.pointerEvents = ''; // Reset interactivity
                } else {
                    console.error(`Element with data-name-id="${nameId}" not found in #available-names.`);
                }

                // Update hidden input
                selectedNameIdsInput.value = [...selectedNamesDiv.querySelectorAll('.selected-item')]
                    .map(item => item.getAttribute('data-name-id'))
                    .join(',');
            }
        });
    }
});
